<template>
  <b-tabs
    v-if="show"
    v-model="tabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Geral</span>
      </template>

      <account-setting-general
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Trocar senha</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CodeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Integração</span>
      </template>

      <api-integration />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import ApiIntegration from './ApiIntegration.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    ApiIntegration
  },
  data: () => ({
    show: false,
    tabIndex: 0,
    options: {}
  }),
  async mounted () {
    await this.getProfile()

    setTimeout(() => {
      this.tabIndex = parseInt(this.$router.currentRoute.query.tab)
    }, 1000)
  },
  methods: {
    async getProfile () {
      await this.$http.get('/user/profile').then(res => {
        this.options.general = res.data
      }).finally(() => {
        this.show = true
      })
    }
  }
}
</script>
