<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Senha antiga"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Senha antiga"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="Nova senha"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Nova senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Confirme a nova senha"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="retypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Confirme a nova senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="!checkPasswordInputIsFilled"
            @click.stop="update()"
          >
            Salvar alterações
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Limpar
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend
  },
  directives: {
    Ripple
  },
  data () {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password'
    }
  },
  computed: {
    passwordToggleIconOld () {
      return this.passwordFieldTypeOld === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconNew () {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype () {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    checkPasswordInputIsFilled () {
      return this.passwordValueOld.length > 0
    }
  },
  methods: {
    togglePasswordOld () {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew () {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype () {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    passwordDoesntMatchError () {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'A confirmação da senha está incorreta!',
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    },
    async update () {
      if (this.newPasswordValue !== this.retypePassword) {
        return this.passwordDoesntMatchError()
      }

      await this.$http
        .post('/user/profile/change_password', {
          old_password: this.passwordValueOld,
          new_password: this.newPasswordValue,
          new_password_confirmation: this.retypePassword
        })
        .then(res => {
          if (res.data.error === 'old password is wrong.') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'A senha antiga está incorreta!',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
            return
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sua senha foi atualizada!',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
    }
  }
}
</script>
