<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            v-if="!(optionsLocal.photo === null || optionsLocal.photo === '')"
            ref="previewEl"
            rounded
            :src="`${$api_host}${optionsLocal.photo}`"
            height="150"
            width="150"
          />
          <b-img
            v-else
            ref="previewEl"
            rounded
            :src="require('@/assets/images/avatars/default.jpeg')"
            height="150"
            width="150"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpeg, .jpg, .png"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Limpar
        </b-button>
        <!--/ reset -->
        <b-card-text>Permitido apenas JPG, ou PNG.</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nome"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.full_name"
              placeholder="Nome"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Telefone (Whatsapp)"
            label-for="account-phone"
          >
            <b-form-input
              v-model="optionsLocal.phone"
              v-mask="'(##) #####-####'"
              name="phone"
              placeholder="(11) 95555-5555"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              :disabled="true"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Data de nascimento"
            label-for="account-birthday"
          >
            <b-form-input
              v-model="optionsLocal.birthday"
              v-mask="'##/##/####'"
              name="birthday"
              placeholder="00/00/0000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.stop="update()"
          >
            Atualizar dados
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Limpar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink
  },
  directives: {
    Ripple,
    mask
  },
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null
    }
  },
  methods: {
    resetForm () {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    async update () {
      const params = new FormData()
      params.append('full_name', this.optionsLocal.full_name)
      params.append('phone', this.optionsLocal.phone)

      if (!(this.optionsLocal.birthday === null || this.optionsLocal.birthday === '')) {
        params.append('birthday', this.optionsLocal.birthday)
      }

      if (this.$refs.refInputEl.files.length > 0) {
        params.append('photo', this.$refs.refInputEl.files[0])
      }

      await this.$http
        .post('/user/profile', params)
        .then(res => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.fullName = res.data.fullName
          userData.avatar = res.data.avatar
          localStorage.removeItem('userData')
          localStorage.setItem('userData', JSON.stringify(userData))
        })
        .finally(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Perfil atualizado com sucesso!',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })

          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
    }
  },
  setup () {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>
