<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <h2 class="mb-1">
            Atenção
          </h2>

          <p class="mt-2">
            1. Sua API Key carrega muitos privilégios, portanto certifique-se de
            mantê-la protegida. Não informe ela em atendimentos e nem a exponha
            no front-end da sua aplicação.
          </p>

          <p class="mt-1 mb-2">
            2. Além disso, não é possível recuperá-la caso perdida, sendo
            necessário a geração de uma nova.
          </p>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="relief-primary"
            :disabled="loading"
            @click.stop="generateNewApiKey()"
          >
            <span v-if="!loading">
              Gerar nova chave de API
            </span>

            <b-spinner
              v-if="loading"
              small
            />
          </b-button>
        </b-col>

        <b-col
          v-if="token !== null"
          cols="12"
          class="mt-2"
        >
          <pre
            style="overflow: hidden; white-space: pre-wrap; font-size: 14px"
            v-html="token"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner
  },
  data: () => ({
    loading: false,
    token: null
  }),
  methods: {
    generateNewApiKey () {
      this.loading = true
      const url = '/user/api_token/generate'

      this.$http.get(url)
        .then(res => {
          this.token = res.data.token
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
